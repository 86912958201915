import React from "react";
import { useTheme, Theme, Container } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import MainLayout from "../layouts/mainLayout";
import SEO from "../components/seo";
import Section from "../components/section";
import ProductGrid from "../components/product-grid";


interface LocationProps {
    location: Location;
}

const ShopPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();

    const data = useStaticQuery(graphql`
        query {
            seo: shopJson(block: {eq: "seo" }) {
                title
                description
                image {
                  childImageSharp {
                      resize(width: 750, height: 300, cropFocus: ENTROPY) {
                          src
                          width
                          height
                      }
                  }
                }
            }
            allShopifyProduct {
                edges {
                    node {
                        handle
                        title
                        variants {
                            shopifyId
                            price
                            compareAtPrice
                        }
                        images {
                            originalSrc
                            id
                            localFile {
                              childImageSharp {
                                fluid(maxWidth: 450, maxHeight: 450) {
                                  ...GatsbyImageSharpFluid_withWebp
                                }
                              }
                            }
                        }
                    }
                }
            }
        } 
    `);

    return <MainLayout>
         <SEO {...data.seo} location={props.location} />

        <Section>
            <Container>
                <ProductGrid products={data.allShopifyProduct.edges.map(x => x.node)}></ProductGrid>
            </Container>
        </Section>
    </MainLayout>;
    
}

export default ShopPage;