import React, { useContext } from "react";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import Img from "gatsby-image";
import LinkButton from "./link-button";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { ShopContext } from "../context/shop-context-provider";

interface ProductListingProps {
    products: ReadonlyArray<ProductListing>;
}

interface ProductVariantPrice {
    price: string;
    compareAtPrice: string;
    shopifyId: string;
}

interface ProductListing {
    handle: string;
    title: string;
    variants: ReadonlyArray<ProductVariantPrice>;
    images: [{
        localFile: import("gatsby-source-filesystem").FileSystemNode;
    }]
}

const ListPrice = styled(Typography)`
    padding: 0px 8px;
    text-decoration: line-through;
`;

const ProductListDisplay: React.FC<ProductListing> = (props) => {
    const linkUrl = `/shop/${props.handle}`;
    const { adding, checkout, addToCart} = useContext(ShopContext);

    var min = props.variants.reduce((prev, curr) => {
        if (!prev)
            curr;

        if (curr.price < prev.price)
            return curr;

        return prev;
    });

    var inCart = checkout.lineItems.some(x => x.variant.id == min.shopifyId);

    const getDiscount = (props: ProductVariantPrice) => {
        var percentOff = 1 - (parseFloat(min.price) / parseFloat(min.compareAtPrice));
        return Math.round(percentOff * 100);
    }

    const renderButton = (inCart: boolean) => {
        if(inCart) {
            return <LinkButton url="cart" color="primary" variant="outlined">View In Cart</LinkButton>
        } else {
            return <Button variant="contained" color="primary" disabled={adding} onClick={() => addToCart(min.shopifyId, 1) }>Add To Cart</Button>;
        }
    }

    return <Box p={1} display="flex" flexDirection="column" justifyContent="center">
        <Link to={linkUrl}>
            <Img fluid={(props.images[0].localFile.childImageSharp as any).fluid} />
        </Link>
        <Box pt={1} minHeight={108} display="flex" justifyContent="center" textAlign="center">
            <LinkButton url={linkUrl}>
                <Typography variant="h4">{props.title}</Typography>
            </LinkButton>
        </Box>
        <Box width="100%" textAlign="center">
            <Typography variant="h6" component="span" color="primary">${min.price}</Typography>
            {min.compareAtPrice && <>
                <ListPrice variant="h6" component="span">${min.compareAtPrice}</ListPrice>
                <Typography variant="h6" component="span">({getDiscount(min)}% off)</Typography>
            </>
            }
        </Box>
        <Box pt={3} pb={2} textAlign="center">
            {renderButton(inCart)}
        </Box>
    </Box>;
}


const ProductGrid: React.FC<ProductListingProps> = (props) => {

    return <Grid container justify="center">
        {props.products.map(p => <Grid key={p.handle} xs={12} sm={4} item><ProductListDisplay {...p} /></Grid>)}
    </Grid>;
}

export default ProductGrid;